//colors
$very-light-primary: #f3f9fb;
$light-primary: #8bf0fb;
$primary: #20b7da;
$dark-primary: #178ba5;
$extremly-dark-primary: #001d25;

$secondary: #20b7da;

$very-light-grey: #fcfcfc;
$light-grey: #f0f2f5;
$grey: #bebebe;
$dark-grey: #666666;
$very-dark-grey: #272727;

$text-color: #000;
$success-green: #52c41a;
$error_red: #db2222;

//transition
%ease {
    -webkit-transition: .25s ease-in-out!important;
    -moz-transition: .25s ease-in-out!important;
    -o-transition: .25s ease-in-out!important;
    transition: .25s ease-in-out!important; }


//media
$lg: "only screen and (min-width: 1200px)";
$md: "only screen and (max-width: 1199px) and (min-width: 992px)";
$sm: "only screen and (max-width: 991px) and (min-width: 768px)";
$xs: "only screen and (max-width: 767px) and (min-width: 480px)";

$hd: "only screen and (-webkit-min-device-pixel-ratio: 1.5)";
$tab-landscape : "only screen and (width:1024px)";
$md-and-less: "only screen and (max-width: 1199px)";
$sm-and-less: "only screen and (max-width: 991px)";
$xs-and-less: "only screen and (max-width: 767px)";
$tiny-and-less: "only screen and (max-width: 479px)";

$sm-and-more: "only screen and (min-width: 768px)";
$md-and-more : "only screen and (min-width: 992px)";
$lg-and-more : "only screen and (max-width: 2000px)";




