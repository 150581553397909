@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:100,200,300,400,500,600,700,800,900');
@import 'variables.sass';

html {
    scroll-behavior: smooth; }
body {
    font-family: 'Josefin Sans',sans-serif;
    color: $text-color;
    font-weight: 400;
    font-size: 16px; }
h1,h2,h3,h4,h5 {
    font-family: 'Josefin Sans',sans-serif;
    font-weight: 300; }

p {
    font-weight: 300; }


